<template>
  <div class="backups">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="text-md-right"
      >
        <v-btn
          v-if="can('router-backup-create')"
          color="primary"
          class="mb-2"
          @click="showConfirmBackupDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Create Backup
        </v-btn>
      </v-col>
    </v-row>
    <backups-table
      :backups-url="'router-backups'"
      :show-confirm-backup-dialog="showConfirmBackupDialog"
      @close-backup-dialog="showConfirmBackupDialog = false"
    />
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import Breadcrumb from '@/components/partials/BreadCrumb.vue'
import BackupsTable from '@/components/tables/RouterBackupsTable.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    Breadcrumb,
    BackupsTable,
  },
  mixins: [
    hasPermission,
  ],
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        { text: 'Backups', disabled: true },
      ],
      icons: {
        mdiPlus,
      },
      showConfirmBackupDialog: false,
    }
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
</script>
